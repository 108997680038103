import { createRouter, createWebHistory } from 'vue-router'

const routes = [

  {
    path: '/',
    name: 'Home',

    component: () => import( '@/views/Home.vue')
  },
  {
    path: '/about',
    name: 'About',

    component: () => import( '@/views/About.vue')
  },
  {
    path: '/testelements',
    name: 'TestElements',

    component: () => import( '@/views/testElements.vue')
  },
  {
    path: '/associations',
    name: 'Associations',

    component: () => import( '@/views/Associations/Association.vue')
  },
  {
    path: '/medecins',
    name: 'Medecins',

    component: () => import( '@/views/Medecins/Medecins.vue')
  },
  {
    path: '/blog',
    name: 'Blog',

    component: () => import( '@/views/Blog/Blog.vue')
  },
  {
    path: '/article/:slug',
    name: 'SingleBlog',

    component: () => import( '@/views/Blog/SingleBlog.vue')
  },
  {
    path: '/forum',
    name: 'Forum',

    component: () => import( '@/views/Forum/Forum.vue')
  },
  {
    path: '/sujet/:slug',
    name: 'SingleSujet',

    component: () => import( '@/views/Forum/SingleSujet.vue')
  },
  {
    path: '/:invaliblepage',
    name: '404Page',

    component: () => import( '@/views/404Page.vue')
  }

]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (to.hash) {
      return {
        el: to.hash, // va vers l'élément avec l'ID correspondant
        behavior: 'smooth', // scroll fluide
      };
    }
    return { top: 0 };}
})

export default router
